<template lang="pug">
  v-container.pb-0.max-width
    v-dialog(v-model="dialog")
      template(v-slot:activator="{ on, attrs }")
        v-btn(
          outlined
          color="primary"
          v-bind="attrs"
          v-on="on"
          class="media-btn"
        )
          | video - {{verbiage}}
      v-card(style="width: 640px; height: 360px; max-width: 100%; max-height: 100%;")
        <iframe width="640" height="360" object-fit="cover" :src="video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</template>

<script>

export default {
  name: 'Dialog',
  props: {
    verbiage: {
      type: String,
      required: false
    },
    video: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss">
  .v-dialog {
    width: min-content;
  }
  .media-btn.v-btn--outlined {
    border: 1px solid #f5780c;
  }
</style>
